import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

//assets
import artisanWineCoLogo from '../assets/images/artisan-wine-co-logo.png'
import artisanWineCoLogoGreen from '../assets/images/artisan-wine-co-logo--green.png'
import { useEffect } from 'react'

//preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const Header = ({disabledMenu, customPage}) => {
  const queryData = useStaticQuery(graphql`
  {
    prismicTopMenu {
      _previewable
      data {
        menu_links {
          label {
            text
          }
          link {
            url
            id
            isBroken
            lang
            link_type
            raw
            size
            slug
            tags
            target
            type
            uid
          }
        }
      }
    }
  }
  `)

  const { data } = useMergePrismicPreviewData(queryData)

  const navigation = data.prismicTopMenu
  const topNav = navigation.data.menu_links

  const [showCollapsedNavbar, setShowCollapsedNavbar] = useState(false)
  const [scrollNavbar, setScrollNavbar] = useState(false)

  //scroll the screen
  const handleScroll = () => {
    if(window.scrollY < 90){
      setScrollNavbar(false)
    }else{
      setScrollNavbar(true)
    }
  }

  //create an event listener
  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll)

    document.body.classList.remove('overflow-hidden')
    document.documentElement.className = ''
  }, [])

  var headerClass = (scrollNavbar)? ' scrolled' : ''

  if(disabledMenu){
    headerClass += " header--age-gate"
  }
  //get header menus
  return (
    <header
      className = {(customPage)? "header d-flex align-items-center header--" + customPage + headerClass 
      : "header d-flex align-items-center" + headerClass}
      >
      <div className='container'>
        <nav className='navbar-expand-lg navbar'>
          <div className='header__logo'>
            <Link to="/">
              {(customPage === "grow-wild" && !showCollapsedNavbar && !scrollNavbar)?
                <img src={artisanWineCoLogoGreen} alt="Artisan Wine Co Logo" /> :
                <img src={artisanWineCoLogo} alt="Artisan Wine Co Logo" />
              }
            </Link>
          </div>
          {/* Menu */}
          {(disabledMenu)?
          [] :
          <button className={(showCollapsedNavbar)? "navbar-toggler show" : "navbar-toggler"} onClick={() => {
            //collapse navbar
            if(showCollapsedNavbar){
              //body not scrollable to scrollable
              document.body.classList.remove('overflow-hidden')
              document.documentElement.className = ''
            }else{
              //body scrollable to not scrollable
              document.body.classList.add('overflow-hidden')
              document.documentElement.className = 'overflow-hidden'
            }
            setShowCollapsedNavbar(!showCollapsedNavbar)
          }}>
            <div className="hamburger vertical-center">
              <div className="bar1"></div>
              <div className="bar2"></div>
            </div>
          </button>
          }
          {(disabledMenu)?
          [] :
          <div className={(showCollapsedNavbar)? 'collapse navbar-collapse flex-row-reverse show' : 'collapse navbar-collapse flex-row-reverse'} id="navbarNav">
            <ul className='navbar-nav'>
              {topNav.map((navItem, index) => {
                if(navItem.link.link_type === "Document" && navItem.link.uid){
                  return (
                    <li key={`link-${index}`} className='nav-item doc'>
                      <Link to={"/" + navItem.link.uid}>
                        {navItem.label.text}
                      </Link>
                    </li>
                  )
                }
                return (
                  <li key={`link-${index}`} className='nav-item web'>
                    <a href={navItem.link.url} target={(navItem.link.target)? navItem.link.target : "_self"}>
                      {navItem.label.text}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
          }
        </nav>
      </div>
    </header>
  )
}

export default Header
