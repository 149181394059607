import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

export const Seo = ({ description, title }) => {
  const location = useLocation()

  const queryData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const metaTitle = title
    ? title
    : queryData.site?.siteMetadata?.title
  const metaDescription =
    description || queryData.site?.siteMetadata?.description

  const isNoIndexPage = location.href?.includes('dev.') || !location.pathname?.includes('wishpond')

  return (
    <Helmet>
      <html lang="en"/>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="viewport" content="width=420, initial-scale=0.85, maximum-scale=0.85" />

      {/* robots noindex, nofollow for dev */}
      {isNoIndexPage && <meta name="robots" content="noindex, nofollow"/>}
    </Helmet>
  )
}
